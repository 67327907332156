import React, { useState } from "react";
import { ChromePicker } from "react-color";
import "../TeamComponent.css";

const EditTeamColor = ({
  teamTag,
  setTeamTag,
  onCancel,
  primaryColor,
  setPrimaryColor,
  secondaryColor,
  setSecondaryColor,
}) => {
  const [selectedTag, setSelectedTag] = useState(teamTag);
  const [activeColorPicker, setActiveColorPicker] = useState(null);

  const handleColorChange = (color, event) => {
    setPrimaryColor(color.hex);
  };

  const handleSecondaryColorChange = (color, event) => {
    setSecondaryColor(color.hex);
  };

  const handleTagChange = (tag) => {
    setSelectedTag(tag);
  };

  const handleSave = () => {
    setTeamTag(selectedTag);
  };

  const handleCancel = () => {
    setSelectedTag(teamTag);
  };

  return (
    <div className="EditTeamColor">
      <div className="row pt-2 pb-0">
        <div className="col-6">
          <div
            className={`text-center border-style w-100 home-colors ${
              selectedTag === "HOME" ? "active" : ""
            }`}
            onClick={() => handleTagChange("HOME")}
            tabIndex="1"
          >
            <span className="d-block">Home</span>
          </div>
        </div>
        <div className="col-6">
          <div
            className={`text-center border-style w-100 away-colors ${
              selectedTag === "AWAY" ? "active" : ""
            }`}
            onClick={() => handleTagChange("AWAY")}
            tabIndex="1"
          >
            <span className="d-block">Away</span>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div
            className={`custom-colors text-center border-style ${
              selectedTag === "CUSTOM" ? "active" : ""
            }`}
            tabIndex="1"
            onClick={() => handleTagChange("CUSTOM")}
            style={{
              "--before-background": primaryColor,
              "--after-background": secondaryColor,
            }}
          >
            <span className="d-block">Custom</span>
            <div
              className="primary-color-selector"
              onClick={() => setActiveColorPicker("primary")}
            ></div>
            <div
              className="secondary-color-selector"
              onClick={() => setActiveColorPicker("secondary")}
            ></div>
          </div>

          {selectedTag === "CUSTOM" && (
            <div className="color-picker-container">
              {activeColorPicker === "primary" && (
                <div className="primary-color-picker-wrapper">
                  PRIMARY COLOR
                  <ChromePicker
                    color={primaryColor}
                    onChangeComplete={handleColorChange}
                  />
                </div>
              )}
              {activeColorPicker === "secondary" && (
                <div className="secondary-color-picker-wrapper">
                  SECONDARY COLOR
                  <ChromePicker
                    color={secondaryColor}
                    onChangeComplete={handleSecondaryColorChange}
                  />
                </div>
              )}
            </div>
          )}
        </div>

        <div className="w-50 py-2">
          <button
            className="save mb-2 btn text-uppercase btn-yellow btn-block d-block"
            onClick={handleSave}
          >
            Save
          </button>
          <button
            className="cancel btn text-uppercase btn-secondary btn-block d-block"
            onClick={onCancel}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditTeamColor;
