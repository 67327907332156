import React, { useContext } from "react";
import AuthContext from "../context/AuthContext";
import logo from "../images/logo.png";
const LoginPage = () => {
  let { loginUser } = useContext(AuthContext);
  return (
    <div className="relative h-screen w-full bg-[url('images/background.png')] bg-no-repeat bg-center bg-fixed bg-cover">
      <div className="bg-black/50 w-full h-full">
        <nav className="px-10">
          <img src={logo} alt="logo" className="h-30 w-40" />
        </nav>
        <div className="flex justify-center">
          <div className="bg-black/70 px-16 py-16 self-center mt-2 lg:w-2/5 lg:max-w-md rounded-md w-full">
            <h2 className="text-white text-4xl mb-8 font-semibold">Sign In</h2>

            <form className="flex flex-col gap-3" onSubmit={loginUser}>
              <input
                className="block rounded-md px-2 pt-2 pb-2 py-2 w-full text-white bg-neutral-700 appearance-none focus:outline-none focus:ring-0"
                type="text"
                name="username"
                placeholder="Enter Username"
              />
              <input
                className="block rounded-md px-2 pt-2 pb-2 w-full text-white bg-neutral-700 appearance-none focus:outline-none focus:ring-0"
                type="password"
                name="password"
                placeholder="Enter Password"
              />
              <input
                className="bg-[#002D74] text-white rounded-md py-2 w-full mt-10"
                type="submit"
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
