import React from "react";
import Card from "./Card.js";
import styled from "styled-components";
import SaveCurrentGame from "./SaveCurrentGame.js";
import ResetLive from "./ResetLive.js";

function Sidebar() {
  return (
    <div className="col-3 sidebar">
      <button className="mb-3 btn text-uppercase btn-red btn-block d-block">
        • Send Live
      </button>
      <Card />
      <Card />
      <Card />
      <Card />
      <SaveCurrentGame />
      <ResetLive />
    </div>
  );
}

export default Sidebar;
