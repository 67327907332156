import React from "react";

const ResetLive = () => {
  return (
    <button className="mb-3 btn text-uppercase btn-black btn-block d-block">
      • Reset to Live
    </button>
  );
};

export default ResetLive;
