import React from "react";

const SelectedTeamContext = React.createContext({
  selectedBlueTeam: null,
  selectedOrangeTeam: null,
  setSelectedBlueTeam: () => {},
  setSelectedOrangeTeam: () => {},
});

export default SelectedTeamContext;
