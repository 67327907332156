import React, { useState, useEffect, useContext } from "react";
import SelectedTeamContext from "../context/SelectedTeamConext";

function GameScores() {
  const [columns, setColumns] = useState(5);
  const [gamesList, setGamesList] = useState([]);
  const [blueTeam, setBlueTeam] = useState([]);
  const [orangeTeam, setOrangeTeam] = useState([]);
  const [winningTeam, setWinningTeam] = useState(null);
  const [losingTeam, setLosingTeam] = useState(null);

  const { selectedBlueTeam, selectedOrangeTeam } =
    useContext(SelectedTeamContext);

  const handleChange = (e) => {
    const value = parseInt(e.target.value);
    if (!isNaN(value)) {
      setColumns(value);
    }
  };

  const handleGameScoreChange = (e, index, team) => {
    const score = parseInt(e.target.value);
    if (team === "blue") {
      setBlueTeam((prev) => {
        const updated = [...prev];
        updated[index].score = score;
        return updated;
      });
    } else if (team === "orange") {
      setOrangeTeam((prev) => {
        const updated = [...prev];
        updated[index].score = score;
        return updated;
      });
    }
  };

  const calculateWinner = () => {
    let blueWins = 0;
    let orangeWins = 0;
    blueTeam.forEach((game, index) => {
      if (game.score > orangeTeam[index].score) {
        blueWins += 1;
      } else if (game.score < orangeTeam[index].score) {
        orangeWins += 1;
      }
    });
    if (blueWins > orangeWins) {
      setWinningTeam(selectedBlueTeam || "Blue Team Name");
      setLosingTeam(selectedOrangeTeam || "Orange Team Name");
    } else if (orangeWins > blueWins) {
      setWinningTeam(selectedOrangeTeam || "Orange Team Name");
      setLosingTeam(selectedBlueTeam || "Blue Team Name");
    } else {
      setWinningTeam(null);
      setLosingTeam(null);
    }
  };

  useEffect(() => {
    calculateWinner();
  }, [blueTeam, orangeTeam]);

  const displayWinningTeam = () => {
    console.log(`Winning team: ${winningTeam}`);
    console.log(`Losing team: ${losingTeam}`);
  };

  useEffect(() => {
    const games = Array.from({ length: columns }, (_, i) => `Game ${i + 1}`);
    setGamesList(games.map((game, index) => <th key={index + 1}>{game}</th>));

    const createTeamList = (team) => {
      const oldTeam = team === "blue" ? blueTeam : orangeTeam;
      const newTeam = games.map((_, index) => {
        let score = oldTeam[index]?.score || 0;
        return {
          score: score,
          jsx: (
            <td key={index + 1}>
              <input
                type="number"
                min="0"
                id={index + 1}
                defaultValue={score} // Change here
                className="score-input"
                onChange={(e) => handleGameScoreChange(e, index, team)}
                placeholder="0"
              />
            </td>
          ),
        };
      });
      return newTeam;
    };

    setBlueTeam(createTeamList("blue"));
    setOrangeTeam(createTeamList("orange"));
  }, [columns]);

  return (
    <div>
      <table className="table text-uppercase scoreboard">
        <thead>
          <tr className="table-head">
            <th scope="col" width="30%">
              <div className="me-3 best-of-container">
                Best of:{" "}
                <input
                  className="form-control d-inline best-of"
                  type="text"
                  placeholder={columns}
                  aria-label="best of"
                  onChange={handleChange}
                />
              </div>
            </th>
            {gamesList}
          </tr>
        </thead>
        <tbody>
          <tr className="text-center">
            <td width="30%" className="blue-team">
              {selectedBlueTeam || "Blue Team Name"}
            </td>
            {blueTeam.map((game) => game.jsx)}
          </tr>
          <tr className="text-center">
            <td className="orange-team">
              {selectedOrangeTeam || "Orange Team Name"}
            </td>
            {orangeTeam.map((game) => game.jsx)}
          </tr>
        </tbody>
      </table>
      <button onClick={displayWinningTeam}>Display Winning Team</button>
    </div>
  );
}

export default GameScores;
