import React from "react";

function Card() {
  return (
    <div className="card mb-2">
      <div className="card-body text-uppercase">
        <ul className="list-group list-group-flush">
          <li className="blue-team list-group-item d-flex justify-content-between align-items-center py-0 small">
            Blue Team Name <span className="badge px-0">0</span>
          </li>
          <li className="orange-team list-group-item d-flex justify-content-between align-items-center py-0 small">
            Orange Team Name <span className="badge px-0">2</span>
          </li>
          <li className="list-group-item d-flex justify-content-between align-items-center pb-0 small">
            CCA1 Winter Classic <span className="badge px-0">Game 3</span>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Card;
