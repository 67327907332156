import React, { useState } from "react";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import GameScores from "../components/GameScores";
import Team from "../components/Team";
import EventPanel from "../components/EventPanel";
import SelectedTeamContext from "../context/SelectedTeamConext";
import SelectedEventContext from "../context/SelectedEventContext";

const HomePage = () => {
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedBlueTeam, setSelectedBlueTeam] = useState(null);
  const [selectedOrangeTeam, setSelectedOrangeTeam] = useState(null);

  return (
    <SelectedEventContext.Provider
      value={{
        selectedEvent,
        setSelectedEvent,
      }}
    >
      <SelectedTeamContext.Provider
        value={{
          selectedBlueTeam,
          setSelectedBlueTeam,
          selectedOrangeTeam,
          setSelectedOrangeTeam,
        }}
      >
        <div className="App">
          <Navbar />
          <main className="main">
            <div className="container-fluid">
              <div className="row">
                <Sidebar />
                <div className="col-9">
                  <EventPanel />
                  <GameScores />
                  <div className="row">
                    <div className="col-6">
                      <Team team="blue" />
                    </div>
                    <div className="col-6">
                      <Team team="orange" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </SelectedTeamContext.Provider>
    </SelectedEventContext.Provider>
  );
};

export default HomePage;
