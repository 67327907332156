import React, { useState, useRef, useEffect, useContext } from "react";
import Edit from "./Edit";
import Revert from "./Revert";
import EditTeamColor from "./EditTeamColor";
import axios from "axios";
import AuthContext from "../context/AuthContext";
import "../TeamComponent.css";
import SelectedEventContext from "../context/SelectedEventContext";
import SelectedTeamContext from "../context/SelectedTeamConext";

const TeamComponent = ({ team }) => {
  const [teamName, setTeamName] = useState("");
  const [previousTeamName, setPreviousTeamName] = useState("");
  const [teamNameEditable, setTeamNameEditable] = useState(false);
  const [players, setPlayers] = useState(["", "", ""]);
  const [previousPlayers, setPreviousPlayers] = useState(["", "", ""]);
  const [playersEditable, setPlayersEditable] = useState([false, false, false]);
  const [teamTag, setTeamTag] = useState("");
  const [showColorEditor, setShowColorEditor] = useState(false);
  const [primaryColor, setPrimaryColor] = useState("#fff");
  const [secondaryColor, setSecondaryColor] = useState("#000");
  const [initialPrimaryColor, setInitialPrimaryColor] = useState("#fff");
  const [initialSecondaryColor, setInitialSecondaryColor] = useState("#000");
  const [searchTerm, setSearchTerm] = useState("");
  const [teams, setTeams] = useState([]);
  const [selectedTeamId, setSelectedTeamId] = useState(null);

  const playerInputRefs = useRef(players.map(() => React.createRef()));
  const inputRef = useRef(null);

  const { selectedEvent } = useContext(SelectedEventContext);
  const { setSelectedBlueTeam, setSelectedOrangeTeam } =
    useContext(SelectedTeamContext);

  let { authTokens } = useContext(AuthContext);

  useEffect(() => {
    if (selectedTeamId) {
      axios
        .get(`http://127.0.0.1:8000/api/ccaplayers/?search=${selectedTeamId}`)
        .then((res) => {
          const playerNames = res.data.map((player) => player.gamertag);
          setPlayers(playerNames);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [selectedTeamId]);

  const handleTeamSelect = (teamName, teamId) => {
    setSearchTerm(teamName);
    setSelectedTeamId(teamId);

    if (team === "blue") {
      setSelectedBlueTeam(teamName);
    } else if (team === "orange") {
      setSelectedOrangeTeam(teamName);
    }
  };

  const handleColorEditClick = () => {
    setInitialPrimaryColor(primaryColor);
    setInitialSecondaryColor(secondaryColor);
    setShowColorEditor(true);
  };

  const handleTagChange = (newTag) => {
    setTeamTag(newTag);
    setShowColorEditor(false);
  };

  const handleColorEditCancel = () => {
    setPrimaryColor(initialPrimaryColor);
    setSecondaryColor(initialSecondaryColor);
    setShowColorEditor(false);
  };

  useEffect(() => {
    playerInputRefs.current = playerInputRefs.current.slice(0, players.length);
  }, [players]);

  useEffect(() => {
    let url = `http://127.0.0.1:8000/api/ccateams/?search=${selectedEvent}`;

    axios
      .get(
        url,
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
        { withCredentials: true },
        (axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${authTokens["access"]}`)
      )
      .then((res) => {
        setTeams(res.data);
        console.log(teams);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [selectedEvent]);

  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleEditClick = () => {
    if (!teamNameEditable) {
      setPreviousTeamName(teamName);
    }
    setTeamNameEditable(!teamNameEditable);
    if (inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  };

  const handlePlayerEditClick = (index) => {
    if (!playersEditable[index]) {
      const prevPlayers = [...players];
      setPreviousPlayers(prevPlayers);
    }
    const editable = [...playersEditable];
    editable[index] = !editable[index];
    setPlayersEditable(editable);
    if (playerInputRefs.current[index]) {
      playerInputRefs.current[index].current.focus();
      playerInputRefs.current[index].current.select();
    }
  };

  const revertPlayerName = (index) => {
    const oldPlayers = [...previousPlayers];
    const newPlayers = [...players];
    newPlayers[index] = oldPlayers[index];
    setPlayers(newPlayers);
  };

  const handleTeamNameChange = (event) => {
    setTeamName(event.target.value);
  };

  const handlePlayersChange = (index, event) => {
    const values = [...players];
    values[index] = event.target.value;
    setPlayers(values);
  };

  const revertTeamName = () => {
    setTeamName(previousTeamName);
  };

  const displayTeamInput = () => (
    <div>
      <li className="blue-team-name list-group-item d-flex justify-content-between align-items-center py-0">
        <input
          ref={inputRef}
          className="bg-transparent placeholder:text-white"
          value={teamName || searchTerm}
          onChange={handleTeamNameChange}
          placeholder={`${team.toUpperCase()} TEAM NAME`}
          readOnly={!teamNameEditable}
          onBlur={() => setTeamNameEditable(false)}
          onKeyPress={(event) => {
            if (event.key === "Enter") {
              setTeamNameEditable(false);
            }
          }}
        />
        <div className="d-flex">
          <div className="mb-1" onClick={handleEditClick}>
            <Edit />
          </div>
          <button className="mt-[1%]" onClick={revertTeamName}>
            <Revert />
          </button>
        </div>
      </li>
    </div>
  );

  const displayPlayerInput = () => (
    <li className="bluePlayer1 list-group-item d-flex flex-col justify-content-between border-0 py-0">
      {players.map((player, index) => (
        <div
          key={index}
          className="d-flex justify-content-between align-items-center my-2"
        >
          <input
            ref={playerInputRefs.current[index]}
            className="bg-transparent flex-grow placeholder:text-white"
            value={player || ""}
            onChange={(event) => handlePlayersChange(index, event)}
            placeholder={`USERNAME ${index + 1}`}
            readOnly={!playersEditable[index]}
            onBlur={() => {
              const editable = [...playersEditable];
              editable[index] = false;
              setPlayersEditable(editable);
            }}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                const editable = [...playersEditable];
                editable[index] = false;
                setPlayersEditable(editable);
              }
            }}
          />
          <div className="d-flex">
            <div className="mb-1" onClick={() => handlePlayerEditClick(index)}>
              <Edit />
            </div>
            <button className="mt-[1%]" onClick={() => revertPlayerName(index)}>
              <Revert />
            </button>
          </div>
        </div>
      ))}
    </li>
  );
  const displayTeamHeader = () => (
    <div
      className={`${team}-team card-header d-flex justify-content-between align-items-center py-1`}
    >
      {team.charAt(0).toUpperCase() + team.slice(1)} Team
      <div
        className="badge"
        id={`${team}Badge`}
        contentEditable="true"
        style={{ width: 60, display: "block" }}
      >
        7-2
      </div>
    </div>
  );

  const displayTeamTag = () => {
    if (showColorEditor) return null;

    if (teamTag === "HOME") {
      return (
        <div className="col-9 text-center home-colors">
          <span className="d-block">Home</span>
        </div>
      );
    } else if (teamTag === "AWAY") {
      return (
        <div className="col-9 text-center away-colors">
          <span className="d-block">Away</span>
        </div>
      );
    } else if (team === "blue") {
      return (
        <div
          className="col-9 text-center custom-colors-tag blue-tag"
          style={{
            "--before-background": primaryColor,
            "--after-background": secondaryColor,
          }}
        >
          <span className="d-block">Custom</span>
        </div>
      );
    } else if (team === "orange") {
      return (
        <div
          className="col-9 text-center custom-colors-tag orange-tag"
          style={{
            "--before-background": primaryColor,
            "--after-background": secondaryColor,
          }}
        >
          <span className="d-block">Custom</span>
        </div>
      );
    }
  };

  const displayTeamSearch = () => (
    <div className="search-team mt-4 mx-3">
      <input
        className="form-control text-uppercase"
        type="text"
        placeholder="Search for a team..."
        aria-label="Search for a team"
        value={searchTerm}
        onChange={handleSearchTermChange}
      />
      {searchTerm && (
        <ul className="team-search-results">
          {teams
            .filter((team) =>
              team.team_name.toLowerCase().includes(searchTerm.toLowerCase())
            )
            .map((filteredTeam) => (
              <li
                className="cursor-pointer"
                key={filteredTeam.team_id}
                onClick={() =>
                  handleTeamSelect(filteredTeam.team_name, filteredTeam.team_id)
                }
              >
                {filteredTeam.team_name}
              </li>
            ))}
        </ul>
      )}
    </div>
  );

  return (
    <div className="card text-uppercase border-0">
      {displayTeamHeader()}
      <div className="card-body px-0 pt-0">
        <ul className="list-group list-group-flush">{displayTeamInput()}</ul>
        <ul className="player-list my-3 list-group">{displayPlayerInput()}</ul>
      </div>

      <div className="team-colors px-3">
        <div className="row">
          <div className="col-4">Team Colors:</div>

          <div className="row col-8">
            {displayTeamTag()}
            {!showColorEditor && (
              <div className="col-3 edit-colors">
                <button
                  className="edit text-uppercase btn btn-secondary btn-block d-block w-100"
                  onClick={handleColorEditClick}
                >
                  Edit
                </button>
              </div>
            )}
          </div>

          {showColorEditor && (
            <EditTeamColor
              teamTag={teamTag}
              setTeamTag={handleTagChange}
              onCancel={handleColorEditCancel}
              primaryColor={primaryColor}
              setPrimaryColor={setPrimaryColor}
              secondaryColor={secondaryColor}
              setSecondaryColor={setSecondaryColor}
            />
          )}
        </div>
        {displayTeamSearch()}
      </div>
    </div>
  );
};

export default TeamComponent;
