import React from "react";
import logo from "../images/logo-cca.svg";
import { useContext } from "react";
import AuthContext from "../context/AuthContext";

function Navbar() {
  let { user, logoutUser } = useContext(AuthContext);
  return (
    <div>
      <header className="header py-2">
        <div className="container-fluid">
          <div className="row">
            <div className=" header-container d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start text-uppercase">
              <div className="col-3">
                <a
                  href="/"
                  className="d-flex align-items-center mb-lg-0 text-decoration-none home-link"
                >
                  <img src={logo} className="logo" alt="" /> Dashboard
                </a>
              </div>
              <div className="col-6 navigation">
                <ul className="nav nav-pills col-12 col-sm-auto mb-md-0">
                  <li className="nav-item">
                    <a href="#" className="nav-link py-2">
                      Home
                    </a>
                  </li>
                  <li className="nav-item dropdown">
                    <a
                      href="#"
                      className="nav-link py-2 dropdown-toggle active"
                      data-bs-toggle="dropdown"
                      role="button"
                      aria-expanded="false"
                    >
                      Overlay
                    </a>
                    <ul className="dropdown-menu">
                      <li>
                        <a className="dropdown-item" href="#">
                          CCA 1
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          CCA 2
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Winter Classic
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item">
                    <a href="#" className="nav-link py-2">
                      Teams
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="#"
                      className="nav-link py-2"
                      type="button"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvas-matches"
                      aria-controls="offcanvas-matches"
                    >
                      Matches
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-3 account py-1">
                <div className="text-end">
                  Hello, {user.username}!
                  <button
                    type="button"
                    className="btn btn-link flex"
                    onClick={logoutUser}
                  >
                    Logout
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default Navbar;
