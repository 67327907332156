import axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import SelectedEventContext from "../context/SelectedEventContext";
import AuthContext from "../context/AuthContext";

const Pill = ({ label, selected, onClick }) => {
  const pillClass = `pill ${selected ? "pill-selected" : "pill-unselected"}`;

  return (
    <div className={pillClass} onClick={onClick}>
      {label}
    </div>
  );
};

const EventPanel = ({ children }) => {
  const [events, setEvents] = useState([]);
  const { selectedEvent, setSelectedEvent } = useContext(SelectedEventContext);

  let { authTokens } = useContext(AuthContext);

  useEffect(() => {
    axios
      .get(
        "http://127.0.0.1:8000/api/ccaevents/",
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
        { withCredentials: true },
        (axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${authTokens["access"]}`)
      )
      .then((res) => {
        setEvents(res.data);
        console.log(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const selectEvent = (eventId) => {
    setSelectedEvent(eventId);
  };

  return (
    <div className="pill-container mb-1">
      {events.map((event) => (
        <Pill
          key={event.event_id}
          label={event.event_name}
          selected={selectedEvent === event.event_id}
          onClick={() => selectEvent(event.event_id)}
        />
      ))}
    </div>
  );
};

export default EventPanel;
