import React from "react";
import axios from "axios";

const SaveCurrentGame = () => {
  const saveGame = () => {
    const gameData = {
      // Define your game data here. This should match the shape of data expected by your API.
      // For example:
      // title: "Game Title",
      // score: 123,
    };

    axios
      .post("http://127.0.0.1:8000/api/ccaseries/", gameData)
      .then((response) => {
        // handle success
        console.log(response);
      })
      .catch((error) => {
        // handle error
        console.error(error);
      });
  };

  return (
    <div>
      <button
        className="mb-2 mt-3 btn text-uppercase btn-yellow btn-block d-block"
        onClick={saveGame}
      >
        Save Current Game
      </button>
    </div>
  );
};

export default SaveCurrentGame;
